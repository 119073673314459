@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap");

// themes - our custom or/and out of the box themes
@import "themes";

// framework component themes (styles tied to theme variables)
@import "@nebular/theme/styles/globals";
@import "@nebular/auth/styles/globals";

@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/grid";

// loading progress bar theme
@import "./pace.theme";

@import "./layout";
@import "./overrides";

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

// install the framework and custom global styles
@include nb-install() {
  // framework global styles
  @include nb-theme-global();
  @include nb-auth-global();

  @include ngx-layout();
  // loading progress bar
  @include ngx-pace-theme();

  @include nb-overrides();
}

nb-auth {
  nb-card-header {
    display: none;

    .forgot-password {
      display: none;
    }
  }

  nb-checkbox.status-basic.ng-untouched.ng-pristine.ng-valid.nb-transition {
    display: none !important;
  }
}

.pending-status {
  background-color: grey;
  padding: 5px 10px;
  color: #fff;
  border-radius: 4px;
}

.canceled-status,
.rejected-status {
  background-color: red;
  padding: 5px 10px;
  border-radius: 4px;
  color: #fff;
}

.approved-status {
  background-color: green;
  padding: 5px 10px;
  border-radius: 4px;
  color: #fff;
}

.ready_to_picked-status,
.ready_to_shipped-status,
.delivered-status,
.picked-status {
  background-color: gold;
  padding: 5px 10px;
  border-radius: 4px;
  color: #000;
}

nb-select {
  width: 100% !important;
  max-width: 100% !important;
}
